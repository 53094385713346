import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink,Link } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import AssignOrdertoDriverModel from "../../../containers/transportation/AssignOrdertoDriverModel";
import SignShippingModal from "../../../containers/transportation/SignShippingModal";
import ViewTransportationModal from "../../../containers/transportation/ViewRouteModal";
import PrintShippingManifestModal from "../../../containers/transportation/PrintShippingManifestModal";

import moment from "moment";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Transportation extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {	 
			modalOpenPrintShippingManifest: false,
			modalOpenSignShipping: false,
			modalOpenAssign: false,
			modalOpenView: false,
			delivery:[],
			drivers:[],
			delivery_id:0,
			delivery_id2:0,
			c_id:0,
			search: '',
			delivery_count: {},
			user_privileges:[],
			modalOpenAdd:false
		};
		
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleModalSignShipping = this.toggleModalSignShipping.bind(this);
		this.cancelDelivery = this.cancelDelivery.bind(this);
		this.markDelivery = this.markDelivery.bind(this);
		this.toggleModalPrintShippingManifest = this.toggleModalPrintShippingManifest.bind(this);
	}

	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		var user_id=localStorage.getItem('user_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var userData=JSON.parse(localStorage.getItem('user_data'));
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
	formData.append('user_type',userData.user_type);
		axios.post(REST_API_END_POINT+'transportation/get-all-routes',formData).then((res)=>{
			var data = res.data;
		
			if(data.routes){
				var delivery = data.routes;
				var delivery_count ={};
				
				
				// if(drivers.length==0){
				// 	drivers = [{label:"All drivers are busy", value:0, key:0}];
				// }
				
				this.setState({
					delivery: data.routes,
					drivers: data.drivers,
					delivery_count,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				var drivers = [{label:"All drivers are busy", value:0, key:0}];
				this.setState({
					delivery: [],
					drivers,
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalSignShipping = (e,delivery_id=0) => {
		this.setState({
			delivery_id: parseInt(delivery_id),
			modalOpenSignShipping: !this.state.modalOpenSignShipping,
		});
	};
	
	toggleModalAssign = (e,c_id=0) => {
		this.setState({
			c_id: parseInt(c_id),
			modalOpenAssign: !this.state.modalOpenAssign,
		});
	};
	
	toggleModalView = (e,delivery_id=0) => {
		this.setState({
			delivery_id: parseInt(delivery_id),
			modalOpenView: !this.state.modalOpenView
		});
	};
	toggleModalAdd = () => {
		const targetUrl = '/app/point-of-sale/customer-lookup';
		
		
	};
	toggleModalPrintShippingManifest = (e,delivery_id=0) => {
		this.setState({
			delivery_id2: parseInt(delivery_id),
			modalOpenPrintShippingManifest: !this.state.modalOpenPrintShippingManifest
		});
	};
	
	cancelDelivery = (e,id)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        
        swal({
          title: "Are you sure?",
          text: "You want to reject the route?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('assigned_id',id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'transportation/reject-route',formData).then((res)=>{
					var data = res.data;
				
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();					
					this.setState({	modalOpenView: false });
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	handleButtonClick = () => {
		
		 window.location.href = 'https://flashcat-route.gettridant.com/';
		// window.location.href = 'https://localhost:3000';
	  };
	markDelivery = (e,id)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        
        swal({
          title: "Are you sure?",
          text: "You want to Accept this route",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('assigned_id',id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'transportation/accept-route',formData).then((res)=>{
					var data = res.data;
				
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();					
					this.setState({	modalOpenView: false });
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
     
  
	render() {
		let data = this.state.delivery;
		let delivery_count = this.state.delivery_count;
		if (this.state.search) {
			data = data.filter(row => {
				let delivery_status = 'Waiting for driver';
				if(row.delivery_status==1){
					delivery_status = 'Out for delivery';
				}else if(row.delivery_status==2){
					delivery_status = 'Delivered';
				}else if((row.delivery_status==null && row.status==null) || (row.delivery_status==3 && row.status==2)){ 
					delivery_status = 'Waiting for assigning driver';
				}else if(row.delivery_status==5){
					delivery_status = 'Cancelled';
				}else if(row.delivery_status==4){
					delivery_status = 'Returned';
				}
				return row.customer.toLowerCase().includes(this.state.search) || row.order_number.toLowerCase().includes(this.state.search) || row.whole_sale_type.toLowerCase().includes(this.state.search) || row.payment_mode.toLowerCase().includes(this.state.search) || row.direction.toLowerCase().includes(this.state.search)  || delivery_status.toLowerCase().includes(this.state.search) 
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Route #",
				accessor: "id",
				Cell: props => <p className="text-muted">{props.value}</p>
			},	
			{
					Header: "Unique ID",
					accessor: "unique_id",
					Cell: props => <p className="text-muted">{props.value}</p>
			},	
			{
				Header: "Starting Point",
				accessor: "starting_point",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			
			// {
			// 	Header: "Wholesale Type",
			// 	accessor: "whole_sale_type",
			// 	Cell: props => <p className="text-muted">{props.value}</p>
			// },
			// {
			// 	Header: "Payment Type",
			// 	accessor: "payment_mode",
			// 	Cell: props => <p className="text-muted">{props.value}</p>
			// },
			// {
			// 	Header: "Grand Total",
			// 	accessor: "total",
			// 	Cell: props => <p className="text-muted">${props.value}</p>
			// },
			{
				Header: "Destination Point",
				accessor: "destination_point",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Driver",
				accessor: "driver_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "time",
				accessor: "timestamp",
				Cell: props => <p className="text-muted">{moment(props.value*1000).format('MM/DD/YYYY HH:mm')}</p>
			},
			/*{
				Header: "Delivery Date",
				accessor: "delivery_date",
				Cell: props => <p className="text-muted">{props.value!=0 && props.value!=null ? moment(props.value*1000).format('MM/DD/YYYY HH:mm'):""}</p>
			},*/
			{
				Header: "Status",
				accessor: "status",
				Cell: props => {
					let delivery_status = 'Awaiting approval from Driver';
					if(props.value==1){
						delivery_status = 'Accepted';
					}else if(props.value==2){
						delivery_status = 'Awaiting approval from Driver';
					}else if(props.value==3){
						delivery_status = 'Rejected';
					}
					return <p className="text-muted">{delivery_status}</p>
				}
			},
			{
				Header: "Actions",
				accessor: "c_id",
				sortable: false,
				Cell: ( props )=> {
						return (<Fragment>						
							{this.state.user_privileges.indexOf(111)!==-1 || this.state.user_privileges.indexOf(112)!==-1 ? <div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
							{this.state.user_privileges.indexOf(111)!==-1 && !((props.original.delivery_status==null && props.original.status==null) || (props.original.delivery_status==3 && props.original.status==2)) ?<DropdownItem onClick={(e)=> this.toggleModalView(e,props.original.id) } >					
								<span className="d-inline-block">View</span>															
							</DropdownItem>:""}
							
							{/* {!((props.original.delivery_status==null && props.original.status==null) || (props.original.delivery_status==3 && props.original.status==2)) ?<DropdownItem onClick={(e)=> this.toggleModalPrintShippingManifest(e,props.original.id) } >					
								<span className="d-inline-block">Print Shipping Manifest</span>															
							</DropdownItem>:""} */}
							{this.state.user_privileges.indexOf(112)!==-1 && ((props.original.delivery_status==null && props.original.status==null) || (props.original.delivery_status==3 && props.original.status==2) || ((props.original.delivery_status==4 || props.original.delivery_status==5)&& delivery_count[props.original.c_id]==0))? <DropdownItem onClick={(e)=> this.toggleModalAssign(e,props.value) } >					
								<span className="d-inline-block">Assign Driver</span>															
							</DropdownItem>	: ""}
							{this.state.user_privileges.indexOf(112)!==-1 && props.original.delivery_status==2 && (props.original.sign_img=='' || props.original.sign_img==null) ? <DropdownItem onClick={(e)=> this.toggleModalSignShipping(e,props.original.id) } >					
								<span className="d-inline-block">Sign Manifest</span>
							</DropdownItem>:""}
							{this.state.user_privileges.indexOf(112)!==-1 && props.original.delivery_status!=null && props.original.delivery_status!=2 && props.original.delivery_status!=5 && props.original.delivery_status!=4 && props.original.status!=2 ? <DropdownItem onClick={(e)=> this.cancelDelivery(e,props.original.id) } >					
								<span className="d-inline-block">Cancel</span>															
							</DropdownItem>:""}
						  </DropdownMenu>} /></div>:""}
					  </Fragment>) 
				}
			}
		];
	
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="All Routes" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				
				<Card className="p-4">
					<Colxx xxs="12">
						<div id="api_message">
						<div className="float-sm-right">
						{" "}{this.state.user_privileges.indexOf(49)!==-1 ? <Button
							  color="primary"
							  size="sm"
							  className=""
							 
							  onClick={this.handleButtonClick}
							>
							 {/* <Link to="/app/point-of-sale/customer-lookup"><span style={{color:'#fff'}}>Create Order</span></Link> */}
							Create Route
						</Button>:""}
						  
					</div>
						</div>
					</Colxx>
					<Row>
						<Colxx xxs="12">
							<div className="mb-4 dropdown-edit">
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>			
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={10}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "send_on",desc: false}]}
								/>
							</div>
						</Colxx>
					</Row>
					
					{this.state.modalOpenAssign ? <AssignOrdertoDriverModel
						modalOpen={this.state.modalOpenAssign}
						toggleModal={this.toggleModalAssign}
						dataListRender={this.dataListRender}
						order_id={this.state.c_id}
						drivers={this.state.drivers}
					/>:""}
					
					<SignShippingModal
						modalOpen={this.state.modalOpenSignShipping}
						toggleModal={this.toggleModalSignShipping}
						dataListRender={this.dataListRender}
						delivery_id={this.state.delivery_id}
					/>
					
					{this.state.modalOpenView ? <ViewTransportationModal
						modalOpen={this.state.modalOpenView}
						toggleModal={this.toggleModalView}
						dataListRender={this.dataListRender}
						cancelDelivery={this.cancelDelivery}
						markDelivery={this.markDelivery}
						toggleModalPrintShippingManifest={this.toggleModalPrintShippingManifest}
						delivery_id={this.state.delivery_id}
					/>:""}
					
					<PrintShippingManifestModal 
						modalOpen={this.state.modalOpenPrintShippingManifest}
						toggleModal={this.toggleModalPrintShippingManifest}
						delivery_id={this.state.delivery_id2}
					/>
				</Card>
			</Fragment>
		);
	}
}
